export enum MessageStatus {
  Draft,
  Sent
}

export enum QuoteOrigin {
  Sub,
  GC
}

export enum QuoteTotalType {
  QuoteAmount,
  PluggedAmount,
  Adjustments
}

export enum AuthenticationMethod {
  None,
  OneClickLink,
  HcssCredentials
}

export enum VendorProposalStatus {
  Sent,
  Received,
  Responded
}

export enum VendorSolicitationStatus {
  SentToSub,
  SubAccepted,
  SubRejected,
  SentToGC,
  GCApproved,
  GCIgnored,
  GCManuallyEntered,
  SyncedToHeavyBid
}

export type SolicitationDashboardFilter = VendorSolicitationStatus | "draft";

export enum VendorSolicitationFilter {
  All,
  New,
  Approved,
  Rejected,
  Pending
}

export enum QuoteAdjustmentType {
  Dollar,
  Percent
}

export enum MessageType {
  Initial = 0,
  Addendum = 1,
  FollowUp = 2,
  Response = 3
}

export enum CommunicationAction {
  SentInitialSolicitationInvite,
  SentFollowupSolicitationInvite,
  SentAddendumSolicitationInvite,
  SubAcceptedInvite,
  SubRejectedInvite,
  SubSubmittedPrices,
  GCReviewedPrices,
  GCRejectedPrices,
  MadePhoneCall,
  ReceivedPhoneCall,
  SentEmail,
  ReceivedEmail,
  SentFax,
  ReceivedFax,
  SentMail,
  ReceivedMail,
  InPersonMeeting,
  GCEnteredPricesOnSubsBehalf,
  GCReconsideredPrices,
  GCManuallyEnteredPrices,
  GCQueuedForSync,
  GCReconsideredManuallyEnteredPrices
}

export enum ExpandableContentStatus {
  expanded = "expanded",
  expanding = "expanding",
  collapsed = "collapsed",
  collapsing = "collapsing"
}

export enum UserType {
  HcssSubscription,
  Subcontractor,
  Guest
}

export enum SharedResourceType {
  VendorSolicitation,
  VendorProposal
}

export enum DataSyncPreferences {
  Neither = "Neither",
  ProjectTracking = "Project Tracking",
  HeavyBid = "HeavyBid"
}

export enum FeatureFlag {
  LinkToHeavyJob = "LinkToHeavyJob",
  ManagedByCompanyAdminField = "ManagedByCompanyAdminFields",
  TableField = "TableField",
  LanguageSelector = "LanguageSelector",
  FrontEndEmailSubscription = "FrontEndEmailSubscription",
  HeavyBidCodeColumn = "HeavyBidCodeColumn",
  NewQuoteManagement = "NewQuoteManagement",
  TakeoffsField = "TakeoffsField",
  QuickPricingPhase1 = "QuickPricingPhase1",
  QuickPricingPhase2 = "QuickPricingPhase2",
  UnifiedPlatformNavigationBar = "UnifiedPlatformNavigationBar",
  ArchivingEnabled = "ArchivingEnabled"
}

export enum LinkedHeavyJobColumnName {
  Description = "description",
  Budgeted = "cost.budgeted",
  Expected = "cost.expected",
  Actual = "cost.actual",
  Variance = "cost.variance"
}

export enum ContactImportName {
  CompanyCode = "companyCode",
  CompanyName = "companyName",
  CompanyWebsite = "companyWebsite",
  CompanyType = "companyType",
  CompanyNotes = "companyNotes",
  FirstName = "firstName",
  LastName = "lastName",
  Title = "title",
  Email = "email",
  CellNumber = "cellNumber",
  PhoneNumber = "phoneNumber",
  Notes = "notes",
  Nickname = "nickname",
  AddressLine1 = "addressline1",
  AddressLine2 = "addressline2",
  City = "city",
  StateProvince = "stateProvince",
  PostalCode = "postalCode",
  CountryRegion = "countryRegion",
  VendorLocationId = "vendorLocationId"
}

export enum ContactListColumnName {
  FirstName = "firstName",
  LastName = "lastName",
  Title = "title",
  EmailAddress = "emailAddress",
  CellPhoneNumber = "cellPhoneNumber",
  PhoneNumber = "phoneNumber",
  IsMainContact = "isMainContact",
  Location = "location",
  Scope = "scope",
  Notes = "note"
}

export enum ExportFileType {
  Csv = "CSV",
  Excel = "EXCEL"
}

export enum NylasSyncState {
  Valid = "valid",
  Invalid = "invalid"
}

export enum ModalState {
  Hidden,
  ShowDelete,
  ShowArchive
}
