import { datadogRum } from "@datadog/browser-rum";
import config from "../config";
//@ts-ignore
import { VERSION } from "../constants.mts";

const version = VERSION || "default-version";

export const initializeDatadogRum = () => {
  const { rumEnabled, rumConfigOptions } = config.rum || {
    rumEnabled: false,
    rumConfigOptions: null
  };

  if (!rumEnabled || !rumConfigOptions) {
    console.info("Datadog RUM is disabled or not properly configured.");
    return;
  }

  rumConfigOptions.version = version;
  console.log("source version", version);

  try {
    datadogRum.init(rumConfigOptions);
    console.info("Datadog RUM initialized successfully.");
  } catch (error) {
    console.error("Failed to initialize Datadog RUM:", error);
  }
};
